
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row">
      <div class="col-md-12">
        <b-button class="btn btn-danger float-right" v-b-modal.add-campaign>Add SMS</b-button>
      </div>
    </div>

    <br/>
    <b-table striped hover :items="campaigns" :fields="columns">
      <template v-slot:cell(loyaltyId)="data">
        <a style="text-decoration: underline; font-weight: bolder;" :href="'/customer/'+data.item.id">{{ data.value }}</a>
      </template>
      <template v-slot:cell(status)="data">
            <span  class="badge badge-success"  v-if="data.value == 'SENT'">
                   {{ data.value }}
                </span>
        <span class="badge badge-danger"  v-else >
                  {{ data.value }}
            </span>
      </template>
      <template v-slot:cell(options)="data">

        <b-dropdown text="options">
          <b-dropdown-item href="#"  v-if="data.item.status != 'SENT'"  @click="openDeleteCampaign(data.item)">Delete</b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-modal id="add-campaign" title="Add Campaign" @cancel="true" @ok="validateBeforeSubmitNewCampaign"   centered>

        <div class="form-body">

          <div class="row">

            <div class="col-md-12">
              <strong>Title: </strong> <br/>
              <input type="text" class="form-control" v-model="sms.title"   />
            </div>

          </div>

          <div class="row" style="margin-top: 20px;">

            <div class="col-md-12">
              <strong>Target: </strong> <br/>
              <select class="form-control" v-model="sms.target" name="target">
                <option value=""> -- Select  -- </option>
                <option value="all">All customers</option>
                <option value="file">Upload File</option>
                <option value="specific">Specific</option>
              </select>
            </div>

          </div>

          <div class="row" v-if="sms.target == 'file'" style="margin-top: 20px;">


            <div class="col-md-12">
              <strong>Excel File: </strong> <br/>
              <input type="file" name="contacts" ref="contacts" @change="handleContactFileUpload" />
            </div>

          </div>

          <div class="row" v-if="sms.target == 'specific'" style="margin-top: 20px;">


            <div class="col-md-12">
              <strong>Criteria: </strong> <br/>
              <select class="form-control" v-model="sms.selectionCriteria">
                <option value=""> -- Select  -- </option>
                <option value="customer">By customer </option>
              </select>
            </div>

          </div>
          <div class="row" v-if="sms.selectionCriteria == 'customer'" style="margin-top: 20px;">

            <div class="col-md-12">

              <label style="font-size: 1.2em;font-weight: 700;">Please enter phone number below</label>
              <div class="input-group mb-3 pull-right">

                <input type="text" v-model="phoneNumber" class="form-control" placeholder="Phone number" aria-label="" aria-describedby="basic-addon1">

                <div class="input-group-append">
                  <button @click="searchCustomer" class="btn btn-primary" type="button">Search</button>
                </div>
              </div>

              <br/>

              <table class="table table-bordered">
                <tr>
                  <th>Select</th>
                  <th>Name</th>
                  <th>Phone number</th>
                </tr>
                <tr v-for="customer in customers">
                  <td> <input type="checkbox" :value="customer" v-model="sms.customers" name="selected_customers" multiple /> </td>
                  <td> {{ customer.name }} </td>
                  <td> {{ customer.mobileNumber }} </td>
                </tr>
              </table>

            </div>

          </div>


          <div class="row" style="margin-top: 20px;" >

            <div class="col-md-12">

              <div class="form-group">
                <label class="control-label" style="font-size: 1.2em;font-weight: 700;">Schedule:</label> <br/>
                <div >
                  <select class="form-control" v-model="sms.schedule">
                    <option value="now">Now</option>
                    <option value="later">Later</option>
                  </select>
                </div>
              </div>

              <div class="form-group " v-if="sms.schedule == 'later'">
                <label class="control-label" style="font-size: 1.2em;font-weight: 700;">Date & time:</label> <br/>
                <div >
                  <date-picker value-type="format" format="YYYY-MM-DD HH:mm" style="width: 100%;" v-model="sms.schedule_date" type="datetime"></date-picker>
                </div>
              </div>
            </div>

          </div>

          <div class="row">

            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label" style="font-size: 1.2em;font-weight: 700;">Message:</label> <br/>
                <div >
                  <textarea class="form-control" style="width: 100%;" rows="10" v-model="sms.description"></textarea>
                </div>
              </div>
            </div>
          </div>

        </div>

    </b-modal>

  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import CodeInput from "vue-verification-code-input"
  import Multiselect from 'vue-multiselect';

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'


export default {
  data() {
    return {
      phoneNumberDuplicate:"",
      phoneNumber:"",
      offset: 4,
      pref:"254",
      disabled:false,
      isLoading: false,
      fullPage: true,
      tiers:["Bronze","Silver","Gold"],
      columns:["title",{
        key: 'description',
        label:'Message',
        sortable: false,
      },{
        key: 'scheduleDateTime',
        label:'scheduleDate',
        sortable: false,
      },"targetSize","status","options"],
      sms:{
        title:"",
        tier:"",
        target:"",
        selectionCriteria:"",
        schedule:"",
        sms_type:"",
        description:"",
        schedule_date:"",
        outlets:[],
        customer_status:"",
        customers:[],
        filePath:""
      },
      customer_statuses:["ACTIVE", "INACTIVE", "SUSPENDED"],
      campaigns:[],
      contacts_file:"",
      outlets:[],
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      customers:[]
    };
  },
  components: {
    Loading,
    DatePicker,
    Multiselect
  },
  computed:{
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    }
  },
  mounted (){
    this.getCampaignsData();
  },
  methods: {
    ...mapActions(["searchCustomerByPhoneNumber","createSMS","getSMSCampaigns","deleteCampaign","uploadFile"]),
    handleContactFileUpload(){
      this.contacts_file = this.$refs.contacts.files[0];
    },

    openDeleteCampaign(sms){

      this.sms = sms;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.sms.title +' from system')
        .then(value => {

          if(value){
            self.confirmDeleteCampaign();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },

    confirmDeleteCampaign(){
      this.isLoading = true;

      let self = this;

      this.deleteCampaign(this.sms).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Campaign deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getCampaignsData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })
    },

    searchCustomer(){

      let self = this;


      if(this.phoneNumber != ""){

        self.isLoading = true;

        this.searchCustomerByPhoneNumber({phone_number: this.phoneNumber})
          .then(function (customers) {


            self.isLoading = false;

            self.customers.push(...customers);

            console.log(self.customers);
          })
          .catch(function (error) {

            self.isLoading = false;

          })
      }
      else {
        alert("Please enter phone number")
      }

    },

    changePage: function (page) {
      this.pagination.current_page = page;
      this.getCustomersData();
    },

    getCampaignsData(){
      let self = this;
      this.getSMSCampaigns()
        .then(function (campaigns) {

          console.log(campaigns);

          self.campaigns = campaigns;

        })
        .catch(function (error) {


          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },

    submitNewSMSCampaign() {
      let self = this;

      self.isLoading = true;

      this.sms.customers = this.sms.customers.map(function (customer){
        return customer.id;
      });

      this.createSMS(this.sms)
          .then(function () {
            self.isLoading = false;

            self.$bvToast.toast("Campaign added.", {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: false
            });

            self.getCampaignsData();

          })
          .catch(function (error) {

            self.isLoading = false;

          })
    },


    validateBeforeSubmitNewCampaign(){
      if(this.sms.target == "file") {
        this.uploadContactFile();
      } else {
        this.submitNewSMSCampaign();
      }
    },

    uploadContactFile() {
      if(this.contacts_file) {
        let self = this;
        let formData = new FormData();
        formData.append('file', this.contacts_file);
        self.isLoading = true;
        this.uploadFile( formData)
            .then(function (response) {
              self.isLoading = false;

              console.log(response.data)

              if(response.data.statusCode == 200){
                self.sms.filePath = response.data.message;

                self.submitNewSMSCampaign();

              }
              else {
                self.$bvToast.toast(response.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });
              }

            })
            .catch(function (error) {

              console.log(error)

              self.isLoading = false;

              self.$bvToast.toast(error.message, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
              });

            })
      } else {
        self.$bvToast.toast("Excel file missing", {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: false
        });
      }
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
